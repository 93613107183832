import React, { useState, useEffect, useRef } from 'react';

import { useCurrenDevice, DEVICES }  from '../../hooks/useCurrentDevice'

import { WrappedMap, WrappedMapLg } from '../../components/dealers/dealerMap';
import BranchOfficesList from '../dealers/branchOfficesList';
import { usePrismComercialLocations } from '../../hooks/use_prims_comercial_locations';
import CloseIcon from '../../components/svg/commons/close_icon';


const API = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCrvecNkmpCUHkEpD-EhbEJR3HIGiHcXKY';

const ComercialDealers = () => {
   
    const allComercialLocation = usePrismComercialLocations();

    const [device] = useCurrenDevice();
    
    const [currentPosition, setCurrentPosition] = useState({latitude: 23.6345005, lngitude: -102.5527878, zoom: 5.5}); // default Mexico location     
    // const [inputSearch, setInputSearch] = useState(null)
    // let stores = []
     /* eslint-disable */
    const [overStore, setOverStore] = useState(null)
    const [currentBranchOffice, setCurrentBranchOffice] = useState(null)
    const [overBranchoffice, setOverBranchoffice] = useState(null)
    const [isBack, setIsBack] = useState(false)
    

    const wrapper = useRef(null);
    const container = useRef(null);
    
    
    const [branchOffices,handleBranchoffice] = useState([])    
    
    const handleBackMain = null
    const [locations,setLocations] = useState([])
  
    
    // set default state and set geolocation
    useEffect( ()  =>  {
        /* if(device === DEVICES.mobile) {
            handleBranchoffice(null);
            handleState(states[0])            
        }  */
        //allComercialLocation
       
        let converted_array = []
        
        allComercialLocation.edges.map((item)=>{
            //console.log()
          return converted_array.push(item.node)
        })
       // stores = converted_array;
        setLocations(converted_array)
       
        handleBranchoffice(converted_array)
        document.querySelector('footer').style.display = "none";
        return () => {
            document.querySelector('footer').style.display = "block";
        };
         
    },[])

    useEffect(()=>{
        
        if(currentBranchOffice!==null)
        {
            setCurrentPosition({latitude: currentBranchOffice.data.coordinates.latitude, lngitude: currentBranchOffice.data.coordinates.longitude, zoom: 5.5})    
        }
        
        
    },[currentBranchOffice])   

    function handleOverBnachoffice (branchoffice)  {
        setOverBranchoffice(branchoffice)
    }

  
    return (
        <section className="c-dealers">
            <div ref={container} className="c-dealers__container">
                <div ref={wrapper} className="c-dealers__container--wrapper">                     
                        
                    <BranchOfficesList
                        branchoffices={branchOffices}
                        handleBranchoffice={handleBranchoffice}
                        handleBackMain={handleBackMain}
                        handleOver={handleOverBnachoffice}
                        isComercial={true}
                        />
                </div>
            
            </div>
            { 
                currentBranchOffice && device === DEVICES.mobile &&
                    <div className="c-dealers__map">
                        <div className="c-dealers__map__container">
                            <div className="c-dealers__map--header">
                                <span>{currentBranchOffice.data.branchoffice}</span>
                                <CloseIcon onClick={() => handleBranchoffice(null)} />
                            </div>
                            <WrappedMap
                                location={currentBranchOffice}
                                googleMapURL={API}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `100%` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                        </div>
                    </div>
            }
            {
                device !== DEVICES.mobile &&
                    <div className="c-dealers__map">
                        <WrappedMapLg
                            location={currentBranchOffice}
                            locations={locations}
                            defaultLocation={currentPosition}
                            googleMapURL={API}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `100%` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            overStore={overStore}
                            overBranchoffice={overBranchoffice}
                            isBack={isBack}
                            setIsBack={setIsBack}
                        />
                    </div>
            }
        </section>
    )
}

export default ComercialDealers;
