import { useStaticQuery, graphql } from "gatsby"
export const usePrismComercialLocations = () => {
const prismic = useStaticQuery(
    graphql`
    query{
        allPrismicComercialLocation{
            edges{
                node{
                    uid
                    data{
                        branchoffice
                        document_id
                        state
                        store
                        address
                        phone
                        website
                        coordinates{
                            latitude
                            longitude
                        }
                    }
                }
            }
        }
      }
    `
  );
 
    return prismic.allPrismicComercialLocation
}